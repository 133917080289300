<template>
	<div>
		<div class="m-banner" v-loading="indexLoading">
			<el-carousel height="514px" indicator-position="always">
				<el-carousel-item v-for="(item,index) in bannerList" :key="index">
					<a :href="item.url" target="_blank"><img :src="item.image" /></a>
				</el-carousel-item>
			</el-carousel>
		</div>
		<!-- 简介 -->
		<!-- <div class="m-jianjie u-container u-m-t-60">
			<div class="m-jianjie-img">
				<img :src="introInfo.cover" />
			</div>
			<div class="m-jianjie-con">
				<p class="u-m-t-30 u-line-5">{{introInfo.synopsis}}</p>
				<div class="m-jianjie-btn" @click="to_link('/intro')">
					<span class="u-flex u-flex-1 u-row-center">查看更多</span>
					<span class="m-jianjie-more">
						<i class="el-icon-arrow-right"></i>
					</span>
				</div>
			</div>
		</div> --> 
		<div class="m-new-box">
			<div class="m-new-h3">
				<span class="u-m-l-20">培训信息</span>
				<span class="m-new-font1 u-m-r-25" @click="selectNav('News',{type:3},2)">
					更多
					<i class="el-icon-arrow-right"></i>
				</span>
			</div>
			<div class="m-new-main">
				<div class="m-new-banner">
					<el-carousel height="300px" indicator-position="always">
						<el-carousel-item v-for="(item,index) in bannerList2" :key="index">
							<div class="m-course" @click="toHref(item)">
							<img :src="item.image" />
							</div>
						</el-carousel-item>
					</el-carousel>
				</div>
				<div class="m-ul">
					<div class="m-li" v-for="item in listNews" @click="to_link('/detail',{id:item.id})">
						<div class="m-li-icon">
							<img src="../../assets/img/common/icon-right.png" />
						</div>
						<div class="m-li-text u-line-1">
							<span>{{item.title}}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 培训 -->
		<div class="m-peixun">
			<div class="u-container u-flex">
				<div class="m-xiaoxi">
					<img src="../../assets/img/index/xiaoxi.png" />
				</div>
				<div class="u-m-l-36">
					<p class="m-peixun-font1">培训信息</p>
					<div class="scrollForerer u-m-t-20">
						<ul class="ul">
							<li class="m-peixun-font2" @click="to_link('/detail',{id:newsList2.id})">{{newsList2.title || ' '}}</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
		<div class="u-container m-con u-m-t-66">
			<div class="m-con-left">
				<p class="m-con-font1">课程导航</p>
				<p class="m-con-font2 u-m-t-16">Course</p>
				<p class="m-con-line u-m-t-16"></p>
				<ul>
					<el-scrollbar style="height: 100%;overflow-x: 0;">
						<li @click="selectType(item)" :class="{'li-active':item.id == courseParams.cate_id}" v-for="(item,index) in typeList">{{item.name}}</li>
					</el-scrollbar>
					<!-- <li>消防学习</li>
					<li>设备学习</li>
					<li>一建学习</li>
					<li>环评学习</li> -->
				</ul>
			</div>
			<div class="m-con-right u-m-l-42" v-loading="courseLoading">
				<div class="m-con-item" v-for="(item,index) in courseList" :key="index" @click="to_course(item)">
					<div class="m-con-item-img">
						<span class="m-con-item-tip">{{item.type == 1 ? '视频' : '文档'}}</span>
						<img :src="item.cover" />
					</div>
					<p class="m-con-item-title u-line-1">
						<span>{{item.name}}</span>
					</p>
					<p class="m-con-item-line"></p>
					<div class="m-con-item-time u-m-t-22">
						<img src="../../assets/img/common/icon-time.png" />
						<span>学时安排：{{item.period}}</span>
					</div>
				</div>
			</div>
		</div>

		<!-- 专家u团队 -->
		<div class="u-container u-m-t-62" style="width: 1350px;">
			<p class="u-flex u-row-center">
				<span class="m-con-font1">专家团队</span>
			</p>
			<p class="u-flex u-row-center u-m-t-16">
				<span class="m-con-font2">our team </span>
			</p>
			<el-carousel class="u-m-t-50" height="220px" indicator-position="none">
				<el-carousel-item :key="index" v-for="(item,index) in teamList">
					<div class="u-flex" style="padding-left: 100px;">
						<div class="m-zhuanjia" :class="{'u-m-l-50':k == 1}" v-for="(v,k) in item">
							<div class="m-zhuanjia-img">
								<img :src="v.image" />
							</div>
							<div class="m-zhuanjia-con">
								<p class="m-zhuanjia-font1">{{v.name}}</p>
								<p class="m-zhuanjia-font2 u-line-5">{{v.synopsis}}</p>
							</div>
						</div>
						<!-- <div class="m-zhuanjia u-m-l-50">
							<div class="m-zhuanjia-img">
								<img src="http://www.zllaw.net/upload/other/image/20210201161217270744.jpg" />
							</div>
							<div class="m-zhuanjia-con">
								<p class="m-zhuanjia-font1">范老师</p>
								<p class="m-zhuanjia-font2 u-line-5">讲师简介：中国安全管理线上平台创建者 从警于中国人民武装警察8673部队，10年武警生涯，公安消防大队上尉副营级级参谋
									曾任职东风日产安全主任，广汽丰田安全主任讲师简介：中国安全管理线上平台创建者 从警于中国人民武装警察8673部队，10年武警生涯，公安消防大队上尉副营级级参谋 曾任职东风日产安全主任，广汽丰田安全主任...</p>
							</div>
						</div> -->
					</div>
				</el-carousel-item>
			</el-carousel>
		</div>


		<!-- 新闻 -->
		<div class="u-container m-con u-m-t-70">
			<div class="m-con-left">
				<p class="m-con-font1">新闻中心</p>
				<p class="m-con-font2 u-m-t-16">OUT NEWS</p>
				<p class="m-con-line u-m-t-16"></p>
				<ul>
					<li :class="{'li-active':newsStatus == 1}" @click="clickNews(1)">最新资讯</li>
					<li :class="{'li-active':newsStatus == 2}" @click="clickNews(2)">新闻动态</li>
					<li :class="{'li-active':newsStatus == 3}" @click="clickNews(3)">培训信息</li>
				</ul>
			</div>
			<div class="m-con-right u-m-l-42" v-loading="newsLoading"> 
				<div class="m-con-item m-con-item2" @click="to_link('/detail',{id:item.id})" v-for="(item,index) in newsList" :key="index">
					<div class="m-con-item-img">
						<img :src="item.cover" />
					</div>
					<p class="m-con-item-title m-con-item-title2 u-line-1">
						<span>{{item.title}}</span>
					</p>
					<p class="m-con-item-line2"></p>
					<div class="m-con-item-text u-line-3 u-m-t-20">
						<span>{{item.sub_detail}}</span>
					</div>
					<p class="m-con-item-line3"></p>
					<div class="u-flex u-row-right u-m-t-20">
						<span class="m-con-item-btn"><i class="el-icon-arrow-right"></i></span>
					</div>
				</div>
			</div>
		</div>
		<div class=" u-m-t-56">
      <img :src="webInfo.web_index_banner" alt="">
    </div>
		<div class="m-box1">
			<div class="u-container">
				<img src="../../assets/img/common/m-bg5.png" />
			</div>
		</div>
		<div class="m-index-menu-list u-m-t-60 u-container"> 
			<div class="m-index-menu" v-for="(item,index) in listIndexBanner" v-if="index < 3" @click="toHref(item)">
				<div class="u-flex u-row-center">
					<img :src="item.image" />
				</div>
				<p class="m-index-h3">{{item.title}}</p>
				<div class="p u-m-t-16" v-html="item.synopsis"></div>
			</div>
			<!-- <div class="m-index-menu" @click="to_link('/register')">
				<div class="u-flex u-row-center">
					<img style="width: 53px;height: 50px;" src="../../assets/img/index/icon-index2.png" />
				</div>
				<p class="m-index-h3">真题、模拟</p>
				<p class="u-m-t-16">章节练习、历年真题</p>
				<p>模拟考试、每日作业</p>
			</div>
			<div class="m-index-menu"  @click="to_link('/register')">
				<div class="u-flex u-row-center">
					<img style="width: 45px;height: 43px;" src="../../assets/img/index/icon-index3.png" />
				</div>
				<p class="m-index-h3">相关资料下载</p>
				<p class="u-m-t-16">视频课件、相关考点、案例</p>
				<p>免费辅助学习</p>
			</div> -->
		</div>
		<div class="m-index-menu-list u-container u-m-t-30"> 
			<div class="m-index-menu" v-for="(item,index) in listIndexBanner" v-if="index > 2" @click="toHref(item)">
				<div class="u-flex u-row-center">
					<img :src="item.image" />
				</div>
				<p class="m-index-h3">{{item.title}}</p>
				<div class="p u-m-t-16" v-html="item.synopsis"></div>
			</div>
			<!-- <div class="m-index-menu" @click="selectNav('News',{type:3},2)">
				<div class="u-flex u-row-center">
					<img style="width: 43px;height: 47px;" src="../../assets/img/index/icon-index4.png" />
				</div>
				<p class="m-index-h3">报考指导</p>
				<p class="u-m-t-16">实习提醒考试公告及报名时间</p>
				<p>提前告知各省现场审核地点及注意事项</p>
			</div>
			<div class="m-index-menu" @click="selectNav('study',{id:1},2)">
				<div class="u-flex u-row-center">
					<img style="width: 49px;height: 43px;" src="../../assets/img/index/icon-index5.png" />
				</div>
				<p class="m-index-h3">专业免费课</p>
				<p class="u-m-t-16">线上公开课，版块化深入详解</p>
				<p>给您高端的课程体验</p>
			</div>
			<div class="m-index-menu">
				<div class="u-flex u-row-center">
					<img style="width: 60px;height: 48px;" src="../../assets/img/index/icon-index6.png" />
				</div>
				<p class="m-index-h3">名师点题</p>
				<p class="u-m-t-16">特邀、聘请专业的老师</p>
				<p>因材施教助力考试</p>
			</div> -->
		</div>
		<!-- 留言 -->
		<!-- <div class="m-form">
			<div class="u-container u-p-t-80">
				<el-row :gutter="15">
					<el-col :span="12">
						<el-input v-model="paramsLiuyan.name" placeholder="*姓名"></el-input>
					</el-col>
					<el-col :span="12">
						<el-input v-model="paramsLiuyan.phone" placeholder="*电话"></el-input>
					</el-col>
				</el-row>
				<el-row :gutter="15" class="u-m-t-15">
					<el-col :span="12">
						<el-input v-model="paramsLiuyan.email" placeholder="*邮箱"></el-input>
					</el-col>
					<el-col :span="12">
						<el-input v-model="paramsLiuyan.profession" placeholder="*职业"></el-input>
					</el-col>
				</el-row>
				<el-row class="u-m-t-15">
					<el-col :span="24">
						<el-input v-model="paramsLiuyan.content" :rows="8" type="textarea" placeholder="*留言"></el-input>
					</el-col>
				</el-row>
				<el-row class="u-m-t-15">
					<el-col :span="24">
						<div class="m-form-submit">
							<div class="u-flex">
								<el-input v-model="paramsLiuyan.code" style="width: 240px;" placeholder="*验证码"></el-input>
								<div class="m-yanzhengma" @click="getCodeImg()">
									<img :src="codeImg" v-if="codeImg" />
								</div>
							</div>
							<div class="m-form-btn" @click="liuyanSubmit">
								<span>提交</span>
							</div>
						</div>
					</el-col>
				</el-row>
			</div>
		</div> -->
		<!-- 友情链接 -->
		<div class="m-friends u-container u-m-t-60 u-m-b-20">
			<p class="m-friends-font1">友情链接</p>
			<p class="m-friends-font2 u-m-t-16">LINKS</p>
			<div class="u-flex u-flex-wrap u-m-t-16 u-p-l-50">
				<a class="u-m-r-20 u-m-b-16" v-for="(item,index) in linkList" :href="item.url" target="_blank"><img :src="item.image"
					 style="width: 346px;height: 50px;" /></a>
			</div>
		</div>


	</div>
</template>

<script>
	import {
		getBanners,
		getTopCate,
		getCourseForCateId,
		getExpertTeam,
		getBlogroll,
		IndexBanner
	} from "@/api/index.js"
	import {getCaptcha,liuyan,getSinglePage} from "@/api/common.js"
	import {
		getNewsInformation
	} from "@/api/news.js"
	import {to_link,to_redirect} from "@/router/api.js"
	import {
		mapState,
		mapMutations,
		mapActions
	} from 'vuex'
	export default {
		name: 'index',
		components: {

		},
		data() {
			return {
				indexLoading:false,
				courseLoading: false,
				newsLoading: false,
				bannerList: [],
				bannerList2:[],
				typeList: [],
				introInfo:{},
				courseParams: {
					cate_id: '',
					page: 1,
					pageNum: 3
				},
				courseList: [],
				teamList: [],
				newsStatus: 1,
				newsList: [],
				newsList2:{},
				
				listNews:[],//培训信息
				
				codeImg:'',
				paramsLiuyan:{
					name:'',
					phone:'',
					email:'',
					profession:'',
					content:'',
					key:'',
					code:''
				},
				paramsLiuyanTip:{
					name:'请填写姓名',
					phone:'请填写手机号',
					email:'请填写邮箱',
					profession:'请填写职业',
					content:'请填写留言内容',
					code:'请填写验证码'
				},
				linkList:[],
				listIndexBanner:[],
				
			}
		},
		computed: {
			...mapState([
				'user_info',
				'isLogin',
				'webInfo'
			])
		},
		created() {
			
		},
		mounted() {
			this.get_banner()
			this.get_banner2()
			this.getNewsList3()
			// this.getIntroInfo()
			this.getType()
			this.get_team_list()
			this.getNewsList()
			this.getNewsList2()
			this.getCodeImg()
			this.getLinkList()
			this.getIndexBanner()
		},
		methods: {
			to_link,
			toHref(item){
				if(!item.url) return false;
				window.location.href = item.url
			},
			getIndexBanner(){
				IndexBanner().then(res=>{
					if(res.code == 200){
						this.listIndexBanner = res.data || []
					}
				})
			},
			selectNav(path, params = {}, type = 1) {
				let pagePath = this.$route.fullPath
				let routeParams = this.$route.query
				let page2= ''
				if(type == 2){
					for(let i in params){
						page2 = page2 + '/' +params[i]
					}
				}
				
				if ((type == 1 && pagePath == path ) || (type == 2 && pagePath == `/${path.toLowerCase()}${page2}`)) return false;
				to_redirect(path, params,type)
			},
			getNewsList3(){
				getNewsInformation({page:1,pageNum:6,status:3}).then(res => {
					console.log(res);
					if (res.code == 200) {
						this.listNews = res.data
					
					} else {
						this.$message.error(res.message)
					}
				})
			},
			//获取友情链接
			getLinkList(){
				getBlogroll({}).then(res=>{
					console.log(res);
					if(res.code == 200){
						this.linkList = res.data || []
					}
				})
			},
			getIntroInfo(){
				this.loading = true
				getSinglePage({id:2}).then(res=>{
					setTimeout(()=>{
						this.loading = false
					},500)
					if(res.code == 200){
						this.introInfo = res.data
					}
				})
				
			},
			get_team_list() {
				getExpertTeam({
					page: 1,
					pageNum: 20
				}).then(res => {
					if (res.code == 200) {
						let data = res.data
						let index = 0
						let arr = []
						data.forEach((item, idx) => {
							if (!arr[index]) {
								arr[index] = []
							}
							if (arr[index].length < 2) {
								arr[index].push(item)
							} else {
								index += 1
								arr[index] = []
								arr[index].push(item)
							}
						})
						this.teamList = arr
					} else {
						this.$message.error(res.message)
					}
				})
			},
			get_banner() {
				this.indexLoading = true
				getBanners().then((res) => {
					setTimeout(()=>{
						this.indexLoading = false
					},500)
					if (res.code == 200) {
						this.bannerList = res.data;
					} else {
						this.$message.error(res.message)
					}
				})
			},
			get_banner2() {
				getBanners({type:2}).then((res) => {
					if (res.code == 200) {
						this.bannerList2 = res.data
					} else {
						this.$message.error(res.message)
					}
				})
			},
			getType() {
				getTopCate().then(res => {
					if (res.code == 200) {
						this.typeList = res.data
						if (this.typeList.length > 0) {
							this.courseParams.cate_id = this.typeList[0].id
							this.getCourseList()
						}
					} else {
						this.$message.error(res.message)
					}
				})
			},
			//选择类型
			selectType(item) {
				if (item.id == this.courseParams.cate_id) return false;
				this.courseParams.cate_id = item.id
				this.getCourseList()
			},
			//获取课程
			getCourseList() {
				this.courseLoading = true
				getCourseForCateId(this.courseParams).then(res => {
					setTimeout(() => {
						this.courseLoading = false
					}, 500)
					if (res.code == 200) {
						this.courseList = res.data
					} else {
						this.$message.error(res.message)
					}
				})
			},
			//课程详情
			to_course(item){
				if(item.is_allot != 1 && this.isLogin){
					this.$message.error('暂未分配次课程，请联系客服添加')
					return false;
				}
				if(item.type == 1){
					to_link('/video',{id:item.id})
				}else{
					to_link('/files',{id:item.id})
				}
			},
			clickNews(status){
				if(status == this.newsStatus) return false;
				this.newsStatus = status
				this.getNewsList()
			},
			//获取新闻列表
			getNewsList() {
				this.newsLoading = true
				getNewsInformation({
					page: 1,
					pageNum: 3,
					status: this.newsStatus
				}).then(res => {
					setTimeout(() => {
						this.newsLoading = false
					}, 500)
					if (res.code == 200) {
						this.newsList = res.data
					} else {
						this.$message.error(res.message)
					}
				})
			},
			//获取首条新闻
			getNewsList2() {
				this.newsLoading = true
				getNewsInformation({
					page: 1,
					pageNum: 1,
					status: 3
				}).then(res => {
					if (res.code == 200) {
						this.newsList2 = res.data[0] || {}
						this.$nextTick(()=>{
							startScroll()
						})
					}
				})
			},
			//获取验证码
			getCodeImg(){
				getCaptcha().then(res=>{
					if(res.code == 200){
						this.codeImg = res.data.img
						this.paramsLiuyan.key = res.data.key
					}
				})
			},
			//提交留言
			async liuyanSubmit(){
				for(let i in this.paramsLiuyanTip){
					if(!this.paramsLiuyan[i]){
						this.$message.error(this.paramsLiuyanTip[i])
						return false
					}
				}
				let res = await liuyan(this.paramsLiuyan)
				if(res.code == 200){
					this.$message.success(res.message)
					this.paramsLiuyan = {
						name:'',
						phone:'',
						email:'',
						profession:'',
						content:'',
						key:'',
						code:''
					}
					this.getCodeImg()
				}else{
					this.$message.error(res.message)
				}
			}
		},
	};
	
	function startScroll(){
			var content = document.querySelector(".scrollForerer")
		     var ul = document.querySelector(".ul")
		     var li = document.querySelectorAll(".m-peixun-font2")
		     var speed = 1;
			let len = 0
			for(let i=0;i<li.length;i++){
				len += li[0].offsetWidth
			}
		     // ul.style.width = li.length * li[0].offsetWidth + "px" 
		     ul.style.width = len + "px" 
		
		     function run() {
		         if (ul.offsetLeft < -ul.offsetWidth) {
		             ul.style.left = 0
		         } else if (ul.offsetLeft > 0) {
		             ul.style.left = +ul.offsetWidth/2 + "px"
		         }
		
		         ul.style.left = ul.offsetLeft - speed + "px"
		     }
		     var timer = setInterval(run, 50)
		
		     content.onmouseover = function() {
		         clearInterval(timer)
		     }
		
		     content.onmouseout = function() {
		         timer = setInterval(run, 50)
		     }
	}
</script>

<style lang="scss">
	.m-course{
		cursor: pointer;
	}
	.scrollForerer{
		position: relative;
		overflow:hidden;
		width: 500px;
		height: 24px;
		.ul{
			width: 500px;
			height: 24px;
			display: flex;
			position: absolute;
		}
		li{
			display: block;
			min-width: 200px;
		}
	}
</style>

<style lang="scss" scoped>
	.m-banner {
		width: 100%;
		height: 514px;

		.el-carousel-item {
			img {
				width: 100%;
				height: 100%;
			}
		}
	}
	
	.m-new-box{
		width: 1200px;
		margin: 0 auto;
		margin-top: 30px;
		.m-new-h3{
			display: flex;
			align-items: center;
			height: 40px;
			justify-content: space-between;
			border-left: 10px solid #3B96EE;
			font-size: 20px;
			color: #000;
			background: #E5E5E5;
		}
		.m-new-font1{
			font-size: 16px;
			color: #1b87ef;
			cursor: pointer;
		}
		.m-new-main{
			display: flex;
			width: 1200px;
			box-sizing: border-box;
			border: 1px solid #D4D4D4;
			border-top: 0;
			padding: 22px 40px;
			.m-new-banner{
				width: 525px;
				height: 300px;
				background: #f4f4f4;
			}
			.m-ul{
				display: flex;
				flex-direction: column;
				width: 550px;
				margin-left: 45px;
				padding-top: 10px;
				.m-li{
					display: flex;
					align-items: center;
					font-size: 18px;
					line-height: 18px;
					color: #000;
					padding: 14px 0;
					cursor: pointer;
				}
				.m-li-icon{
					display: flex;
					align-items: center;
					width: 30px;
					img{
						width: 13px;
						height: 18px;
					}
				}
				.m-li-text{
					width: 520px;
				}
			}
		}
	}

	.m-jianjie {
		display: flex;
		justify-content: space-between;

		.m-jianjie-img {
			width: 420px;
			height: 300px;

			img {
				width: 100%;
				height: 100%;
			}
		}

		.m-jianjie-con {
			position: relative;
			width: 700px;
			font-size: 16px;
			color: #000;
			line-height: 28px;

			.m-jianjie-btn {
				display: flex;
				justify-content: center;
				align-items: center;
				position: absolute;
				bottom: 6px;
				right: 0;
				width: 130px;
				height: 35px;
				background: #42ACDC;
				font-size: 16px;
				color: #fff;
				cursor: pointer;

				.m-jianjie-more {
					display: flex;
					justify-content: center;
					align-items: center;
					width: 30px;
					height: 100%;
					background: #299ACD;
				}
			}
		}
	}

	.m-peixun {
		width: 100%;
		height: 110px;
		background: url(../../assets/img/index/peixun-bg.png) no-repeat;
		background-size: 1920px 110px;
		margin-top: 32px;

		.m-xiaoxi {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 103px;
			height: 110px;
			background: #fff;
		}

		.m-peixun-font1 {
			font-size: 24px;
			color: #403F45;
		}

		.m-peixun-font2 {
			display: block;
			height: 16px;
			font-size: 16px;
			// color: #403F45;
			color: red;
			cursor: pointer;
			&:hover{
				color: red;
			}
		}

	}

	.m-con-font1 {
		font-size: 30px;
		line-height: 30px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #000000;
	}

	.m-con-font2 {
		font-size: 24px;
		line-height: 24px;
		font-family: Arial;
		font-weight: 400;
		color: #9A9A9A;
	}

	.m-con {
		display: flex;
		min-height: 340px;


		.m-con-left {
			display: flex;
			flex-direction: column;
			width: 220px;

			.m-con-line {
				position: relative;
				width: 220px;
				height: 4px;
				background: #DCDCDC;

				&::before {
					content: '';
					width: 100px;
					height: 4px;
					background: #299ACD;
					left: 0;
					top: 0;
					position: absolute;
				}
			}

			ul {
				display: flex;
				flex-direction: column;
				width: 220px;
				height: 230px;
				margin-top: 18px;

				li {
					display: flex;
					align-items: center;
					height: 46px;
					font-size: 18px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #000000;
					cursor: pointer;
				}

				.li-active {
					color: #188EEE;
				}
			}
		}

		.m-con-right {
			display: flex;
			flex: 1;

			.m-con-item {
				width: 280px;
				min-height: 340px;
				background: #42ACDC;
				margin-right: 40px;
				cursor: pointer;

				.m-con-item-img {
					position: relative;
					width: 280px;
					height: 210px;
					display: flex;
					justify-content: center;
					flex-direction: column;
					align-items: center;
					overflow: hidden;

					img {
						width: auto;
						height: 100%;
					}

					.m-con-item-tip {
						display: block;
						position: absolute;
						top: 30px;
						right: 0;
						background: #000;
						color: #fff;
						font-size: 14px;
						width: 56px;
						height: 24px;
						opacity: 0.5;
						text-align: center;
						line-height: 24px;
					}
				}


				.m-con-item-title {
					padding: 32px 6px 0 6px;
					font-size: 16px;
					color: #fff;
					line-height: 16px;
				}

				.m-con-item-title2 {
					color: #000;
				}

				.m-con-item-line {
					position: relative;
					width: 280px;
					height: 1px;
					background: #299ACD;
					margin-top: 22px;

					&::before {
						content: '';
						position: absolute;
						width: 50px;
						height: 3px;
						top: -1px;
						left: 0;
						background: #fff;
					}
				}

				.m-con-item-line2 {
					position: relative;
					width: 280px;
					height: 2px;
					background: #DCDCDC;
					margin-top: 22px;

					&::before {
						content: '';
						position: absolute;
						width: 50px;
						height: 3px;
						top: -1px;
						left: 0;
						background: #898989;
					}
				}

				.m-con-item-line3 {
					position: relative;
					width: 280px;
					height: 2px;
					background: #DCDCDC;
					margin-top: 10px;
				}

				.m-con-item-time {
					display: flex;
					align-items: center;
					margin-left: 6px;
					font-size: 14px;
					line-height: 14px;
					color: #fff;

					img {
						width: 16px;
						height: 16px;
						margin-right: 5px;
					}
				}

				.m-con-item-text {
					height: 66px;
					font-size: 14px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #8D8D8D;
					line-height: 22px;
				}

				.m-con-item-btn {
					width: 30px;
					height: 30px;
					background: #FFAD01;
					color: #fff;
					font-size: 16px;
					text-align: center;
					line-height: 30px;
				}

			}

			.m-con-item2 {
				background: #fff;
			}
		}
	}

	.m-zhuanjia {
		display: flex;
		width: 564px;
		height: 220px;

		.m-zhuanjia-font1 {
			font-size: 22px;
			line-height: 22px;
			font-family: Microsoft YaHei;
			font-weight: bold;
			color: #252525;
			margin-top: 10px;
		}

		.m-zhuanjia-font2 {
			font-size: 16px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #5E5E5E;
			line-height: 28px;
			margin-top: 24px;
		}

		.m-zhuanjia-img {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 220px;
			height: 220px;
			img {
				width: 100%;
				height: 100%;
				border-radius: 50%;
			}
		}

		.m-zhuanjia-con {
			width: 300px;
			margin-left: 40px;
		}
	}

	.m-company {
		margin-top: 76px;
		width: 100%;

		background: #06355b;
		.m-company-font1 {
			line-height: 24px;
			font-size: 24px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #FFFFFF;
		}

		.m-company-font2 {
			line-height: 16px;
			font-size: 16px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #CFCFCF;
		}

		.m-company-font3 {
			line-height: 24px;
			font-size: 24px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #FFFFFF;
		}

		.m-company-font4 {
			line-height: 20px;
			font-size: 20px;
			font-family: Arial;
			font-weight: 400;
			color: #FFFFFF;
		}

		.m-company-box {
			display: flex;
			justify-content: space-between;

			.m-company-left {
				display: flex;
				width: 136px;
				height: 136px;
				background: #42ACDC;
				justify-content: center;
				align-items: center;
			}

			.m-company-center {
				display: flex;
				flex-direction: column;
				flex: 1;
				justify-content: center;
			}

			.m-company-right {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				color: #fff;
				width: 220px;
				height: 136px;
				background: #42ACDC;
			}
		}
	}

	.m-bg1 {
		width: 100%;
		height: 390px;
		background: url(../../assets/img/common/m-bg2.png) no-repeat;
		background-size: cover;
		background-position: center 0;
	}

	.m-box1 {
		display: flex;
		align-items: center;
		width: 100%;
		height: 447px;
		background-image: url(../../assets/img/common/m-bg3.png);
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
	}
	
	.m-form {
		width: 100%;
		height: 558px;
		background-image: url(../../assets/img/common/m-bg4.png);
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;

		.m-form-submit {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}

		.m-yanzhengma {
			display: flex;
			height: 40px;
			background: #BCBCBC;
			margin-left: 12px;
			border-radius: 4px;
			cursor: pointer;
			img{
				height: 100%;
			}
		}

		.m-form-btn {
			width: 196px;
			height: 40px;
			background: #42ACDC;
			border-radius: 5px 5px 5px 5px;
			text-align: center;
			line-height: 40px;
			font-size: 16px;
			color: #fff;
			cursor: pointer;
		}
	}

	.m-friends {
		.m-friends-font1 {
			text-align: center;
			line-height: 30px;
			font-size: 30px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #000000;
		}

		.m-friends-font2 {
			text-align: center;
			line-height: 20px;
			font-size: 20px;
			font-family: Arial;
			font-weight: 400;
			color: #C8C7C7;
		}
	}
	.m-index-menu-list{
		display: flex;
		justify-content: space-between;
		padding: 0 60px;
		.m-index-menu{
			display: flex;
			flex-direction: column;
			justify-content: center;
			width: 300px;
			height: 175px;
			background: #fff;
			margin-right: 70px;
			cursor: pointer;
			&:hover{
				box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.14);
			}
			.p,p{
				text-align: center;
				font-size: 14px;
				color: #8C8C8C;
			}
			.m-index-h3{
				font-size: 20px;
				color: #000000;
				font-weight: 800;
				line-height: 20px;
				margin-top: 16px;
			}
		}
	}
	
</style>

<style lang="scss">
	.m-form {
		.el-input {
			background: #F2F2F2 !important;
			border-radius: 6px;
			font-size: 16px;
			color: #969696;

			input {
				background-color: #F2F2F2 !important;
			}

			input::placeholder {
				color: #969696;
			}
		}

		.el-textarea {
			background: #F2F2F2 !important;
			border-radius: 6px;
			font-size: 16px;
			color: #969696;

			textarea {
				height: 235px !important;
			}

			.el-textarea__inner {
				background-color: #F2F2F2 !important;
				background: #F2F2F2 !important;
			}

			.el-textarea__inner::placeholder {
				color: #969696;
			}
		}
	}
	
	.m-con-left .el-scrollbar__wrap{
		overflow-x: hidden;
	}
</style>
